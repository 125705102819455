@tailwind components;
@layer components {
  .sio-select-header {
    @apply flex px-3 py-3 bg-[#F7F5F2] -mt-2 sticky top-0 z-10 items-center;
  }
  .sio-select-header .Mui-checked {
    @apply grayscale;
  }
  .sio-input-rounded-full {
    @apply !rounded-full;
  }
  .Mui-selected {
    @apply !bg-secondary/10;
  }
  .sio-input-border-none.MuiOutlinedInput-root fieldset {
    border: none;
  }
  .sio-input-border-none.MuiOutlinedInput-root.Mui-focused fieldset {
    border: none;
  }
  .sio-input-border-none.MuiOutlinedInput-root .MuiSvgIcon-root {
    @apply top-0;
  }
  .sio-input-border-none.MuiOutlinedInput-root .MuiSelect-select {
    @apply !pt-0 !ps-0 !pb-0;
  }
  .sio-input-wrap.MuiOutlinedInput-root .MuiSelect-select {
    @apply whitespace-normal p-1;
  }
  .sio-input-xs.MuiOutlinedInput-root .MuiSelect-select {
    @apply text-xs text-secondary-dark;
  }
  .sio-select .MuiMenuItem-root {
    @apply whitespace-normal;
  }
  .sio-menu-check-list .sio-menu-item-align-top {
    @apply whitespace-normal items-start;
  }
  .sio-menu-check-list .MuiCheckbox-root {
    @apply p-0 mt-[2px] me-2;
  }
  .sio-menu-check-list.MuiList-root {
    @apply relative divide-y divide-dark divide-solid;
  }
  .sio-menu-check-list .MuiCheckbox-root,
  .sio-menu-check-list .MuiRadio-root {
    @apply p-[1px] me-2;
  }
  .sio-menu-check-list .Mui-selected,
  .sio-menu-check-list .Mui-selected .MuiListItemText-primary {
    @apply !bg-transparent text-primary-dark;
    .sio-menu-item-title {
      @apply text-primary-dark;
    }
  }
  .sio-menu-check-list .sio-menu-item-title {
    @apply text-secondary-dark text-sm font-semibold font-manrope;
  }
  .sio-menu-check-list .sio-menu-item-subtitle {
    @apply text-secondary-dark text-xs font-normal;
  }
  .MuiMenu-root .MuiPaper-root {
    @apply scrollbar-none;
  }
  .MuiListSubheader-root {
    line-height: 1 !important;
    .sio-check-label {
    }
    .Mui-checked ~ span.sio-check-label {
      @apply text-primary-dark;
    }
  }
  .MuiMenuItem-root {
    .sio-check-label {
    }
    .Mui-checked ~ span.sio-check-label .MuiTypography-root {
      @apply text-primary-dark;
    }
  }
  .sio-input-multiple-group {
    .MuiSelect-multiple {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
  }
  .menu-item-group {
    .MuiMenuItem-root .MuiCheckbox-root {
      @apply ml-6;
    }
  }
  .sio-multiselect-dropdown {
    position: relative;
    .MuiOutlinedInput-root {
      visibility: hidden;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    }
  }

  .sio-select-selected {
    .MuiSelect-icon {
      right: 12px;
    }
    .MuiOutlinedInput-notchedOutline {
      @apply border-primary-700 border-2;
    }
    .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-shrink {
      @apply text-primary-700;
    }
    .MuiInputBase-root.MuiOutlinedInput-root:hover
      .MuiOutlinedInput-notchedOutline {
      @apply border-primary-800;
    }
    .MuiFormLabel-root {
      @apply text-primary-800;
    }
    .MuiListSubheader-root {
      @apply !p-0;
    }
  }
}
