@tailwind components;
@layer components {
  .sio-upload-container.drag-over {
    border: 2px dashed #ccc;
    background-color: #f0f0f0;
  }
  .sio-upload-container {
    @apply bg-dark/5 rounded-lg border-2 border-dark/10 border-dashed flex items-center justify-center text-center min-h-52;
  }
  .sio-upload-info{
    @apply flex items-center justify-center flex-col;
  }
  .drag-info {
    margin-top: 10px;
    font-size: 14px;
    color: #888;
  }
  .error-message {
    margin-top: 10px;
    color: red;
  }

}
