@tailwind components;
@layer components {
  .sio-tabs {
    @apply flex font-semibold sm:text-[15px] md:text-[14px] 3xl:text-[20px];
    .sio-tab-link {
      @apply font-montserrat text-xs md:text-sm font-semibold py-3 px-2 md:px-4;
      &.sio-tab-active-bar {
        @apply relative;
      }
    }
  }
}
