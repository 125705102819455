@tailwind components;
@layer components {
  .sio-toaster.Toastify__toast-container {
    @apply text-sm;
  }
  .sio-toaster .Toastify__toast{
    @apply p-0 min-h-12;
  }
  .sio-toaster .Toastify__toast.Toastify__toast-theme--light{
    @apply text-white text-[13px] font-manrope leading-tight;
  }
  .sio-toaster .Toastify__toast.Toastify__toast-theme--light.Toastify__toast--success{
    @apply bg-secondary/20;
  }
  .sio-toaster .Toastify__toast.Toastify__toast-theme--light.Toastify__toast--error{
    @apply bg-[#CC3100]/15;
  }
  .sio-toaster .Toastify__toast.Toastify__toast-theme--dark{
    @apply bg-dark font-manrope text-[13px] text-white leading-tight;
  }
  .sio-toaster.Toastify__toast-container .Toastify__toast--success{
    @apply bg-dark font-manrope text-[13px] text-white leading-tight;
  }
  .sio-toaster .Toastify__toast-body{
    @apply items-start px-3 py-2;
  }
  .sio-toaster .Toastify__toast-icon{
    @apply size-4;
  }
  .sio-toaster .Toastify__toast-icon svg{
    @apply size-4 mt-[0px];
  }
  .sio-toaster .Toastify__toast--success .Toastify__toast-icon svg{
    @apply mt-[1px];
  }
  .sio-toaster .Toastify__toast--error .Toastify__toast-icon svg{
    @apply mt-[0];
  }

}
