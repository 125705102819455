@tailwind components;
@layer components {
  .sio-flex-table{
    &.sio-flex-table-scroll{
      @apply overflow-y-scroll scrollbar-none;
    }
  }
  .sio-flex-table-row{
    @apply flex py-2 md:gap-3 ps-2 pe-3 md:items-center border-b hover:bg-secondary-100 relative;
  }
  .sio-flex-table-row-read{
    @apply bg-[rgba(247,245,242,0.75)];
  }
  .sio-flex-table-row-read .text-secondary-900{
    @apply text-primary-dark;
  }
  .sio-flex-table-row-read .sio-circle-icon{
    @apply grayscale;
  }
  .sio-flex-table-row-selected{
    @apply bg-secondary-100;
  }
  .sio-flex-table-row-edit{
    @apply border border-[#111111] rounded-lg
  }

  .sio-table .sio-table-thead{
    @apply bg-[#F7F5F2] z-[1];
  }
  .sio-table .sio-table-thead tr{
    @apply bg-gradient-to-b from-dark/10;
  }
  .sio-table .sio-table-thead th{
    @apply text-xs text-secondary-dark uppercase font-bold border-b py-2 px-3;
  }
  .sio-table tr td{
    @apply text-sm text-primary-dark border-b py-4 px-3;
  }
  .sio-table tr td, 
  .sio-table tr th {
    vertical-align: baseline;
    .MuiCheckbox-root{
      margin: -8px -8px -7px -8px;
    }
  }


  .sio-table-card-sm{
    @apply max-md:bg-transparent max-md:shadow-none max-md:border-none;
  }
  .sio-table-card-sm .sio-table-border{
    @apply max-md:border-none;
  }
  .sio-table-card-sm .sio-card-header{
    @apply max-md:bg-white max-md:shadow-md max-md:rounded-lg max-md:border max-md:p-3 max-md:mb-3 max-md:z-10;
  }
  .sio-table-card-sm .sio-table-thead{
    @apply max-md:hidden;
  }
  .sio-table-card-sm .sio-table-tbody{
    @apply max-md:flex max-md:gap-4 max-md:flex-col;
  }
  .sio-table-card-sm .sio-table-tbody tr{
    @apply max-md:w-full max-md:flex max-md:pb-4 max-md:flex-wrap max-md:shadow-md max-md:rounded-lg max-md:border;
  }
  .sio-table-card-sm .sio-table-tbody td{
    @apply max-md:border-0 max-md:p-4 max-md:pb-0;
  }
  .sio-table-card-sm .sio-table-tbody td[data-title]:before {
      color: rgba(0, 0, 0, .54);
      @apply max-md:text-muted34-dark max-md:font-semibold max-md:mb-1 max-md:text-[10px] max-md:content-[attr(data-title)] uppercase block;
  }

  .sio-table-card-md{
    @apply max-lg:bg-transparent max-lg:shadow-none max-lg:border-none;
  }
  .sio-table-card-md .sio-table-border{
    @apply max-lg:border-none;
  }
  .sio-table-card-md .sio-card-header{
    @apply max-lg:bg-white max-lg:shadow-md max-lg:rounded-lg max-lg:border max-lg:p-3 max-lg:mb-3 max-lg:z-10;
  }
  .sio-table-card-md .sio-table-thead{
    @apply max-lg:hidden;
  }
  .sio-table-card-md .sio-table-tbody{
    @apply max-lg:flex max-lg:gap-4 max-lg:flex-col;
  }
  .sio-table-card-md .sio-table-tbody tr{
    @apply max-lg:w-full max-lg:flex max-lg:pb-4 max-lg:flex-wrap max-lg:shadow-md max-lg:rounded-lg max-lg:border;
  }
  .sio-table-card-md .sio-table-tbody td{
    @apply max-lg:border-0 max-lg:p-4 max-lg:pb-0;
  }
  .sio-table-card-md .sio-table-tbody td[data-title]:before {
      color: rgba(0, 0, 0, .54);
      @apply max-lg:text-muted34-dark max-lg:font-semibold max-lg:mb-1 max-lg:text-[10px] max-lg:content-[attr(data-title)] uppercase block;
  }

  .sio-table-col-flex{
    @apply max-lg:flex;
  }
  .sio-table-col-flex[data-title]:before{
    @apply !mb-0 !me-2 pt-[2px];
  }

}