@tailwind components;
@layer components {
  .sio-modal{
    @apply w-[420px];
  }
  .sio-modal .sio-modal-header{
    @apply m-7;
  }
  .sio-modal .sio-modal-body{
    @apply m-7;
  }
  .sio-modal .sio-modal-footer{
    @apply flex gap-4 m-7;
  }
}
