@tailwind components;
@layer components {
  .sio-filter{
    @apply flex flex-col py-3 md:py-4 ps-2 pe-3 border-b shadow-md;
  }
  .sio-recommendation-contener{
    @apply overflow-auto md:overflow-visible;
  }
  .sio-recommendation-contener .sio-tabs{
    @apply sticky md:static top-0 bg-white z-10;
  }
  .sio-recommendation-contener .sio-filter{
    @apply sticky md:static top-[46px] bg-white z-10;
  }
  .sio-btn-add{
    @apply rounded-full md:rounded-lg size-9 md:size-auto md:max-xl:flex;
  }
  .sio-btn-add span{
    @apply hidden md:block;
  }
  .sio-sr-left-bar{
    @apply lg:static fixed right-[4px] left-[4px] md:left-[92px] md:h-[calc(100%-24px)] md:right-[12px] overflow-hidden opacity-0 lg:opacity-100 lg:w-1/3 h-[calc(100%-120px)] lg:h-auto rounded-lg lg:rounded-none bg-white lg:bg-transparent z-10 lg:z-0 -translate-x-[102%] top-[40px] md:top-[12px] lg:translate-x-0 transition-all;
  }
  .sio-sr-left-bar.active{
    @apply -translate-x-[0] opacity-100 rounded-r-lg;
  } 
}