@tailwind components;
@layer components {
  .sio-editor-content {
    @apply leading-6;
  }

  .sio-editor-content :where(ol, ul) > li > :last-child {
    margin-bottom: 0;
  }

  .sio-editor-content :where(ol, ul) > li > :first-child {
    margin-bottom: 0;
    margin-top: 0;
  }

  .sio-editor-content :where(p):not(:where([class~="not-prose"] *)) {
    margin-bottom: 1.25em;
    margin-top: 1.25em;
  }

  .sio-editor-content
    :where(.prose > :first-child):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .sio-editor-content :where(li):not(:where([class~="not-prose"] *)) {
    margin-bottom: 0.5em;
    margin-top: 0.5em;
  }

  .sio-editor-content p {
    @apply mb-5 text-sm md:text-[15px] max-md:leading-relaxed;
  }
  .sio-editor-content h3 {
    &:first-child {
      @apply mt-2;
    }
    @apply text-lg text-primary-800 font-semibold mb-5 mt-8;
    strong {
      @apply font-semibold;
    }
  }
  .sio-editor-content h4 {
    &:first-child {
      @apply mt-2;
    }
    @apply text-base font-semibold mb-4 mt-5 text-primary-dark;
  }
  .sio-editor-content .sio-list-disc {
    @apply mb-5 pl-5 gap-0 text-sm md:text-[15px] max-md:leading-relaxed block list-disc;
  }
  .sio-editor-content .sio-list-disc li{
    @apply text-primary-dark;
  }
  .sio-editor-content .sio-list-disc li::marker {
    @apply text-base;
  }
  .sio-editor-content li > p {
    @apply mb-2;
  }
  .sio-salf {
    > div {
      @apply flex flex-col flex-1;
    }
    @apply w-[60%] self-end mb-5 mt-5;
    .prose.hidden {
      display: none;
    }
  }
  .sio-salf .prose{
    @apply flex items-end;
  }
  .sio-salf .prose p{
    @apply text-secondary-dark rounded-lg bg-secondary-200 flex items-start p-3 mb-0 text-sm;
  }
  .sio-editor-content ol {
    @apply pl-5 list-decimal text-[15px] space-y-2;
    li:marker {
      @apply font-bold;
    }
  }

  .sio-editor-content .sio-table {
    @apply min-w-full bg-white text-sm divide-y;
    tbody tr {
      @apply md:hover:bg-gray-100 border-b;
    }
  }
  .sio-editor-content .sio-table thead {
    @apply bg-warning/5 text-secondary-dark font-bold text-[10px] font-bold;
  }
  .sio-editor-content .sio-table thead th {
    @apply px-4 py-3 text-left font-bold leading-snug;
  }
  .sio-editor-content .sio-table tbody tr td {
    @apply px-4 py-3 text-secondary-dark text-sm align-text-top;
  }
  .sio-editor-content .sio-table-card {
    @apply rounded-lg border shadow overflow-hidden mb-5;
  }

  .sio-chat-edit-col {
    @apply text-secondary-dark rounded-lg bg-secondary-200 p-3 mb-0 text-sm;
  }
  .sio-editor-content .sio-card-border {
    @apply rounded-lg border shadow mb-5 p-4;
  }

  .sio-editor-content .sio-statistics-list {
    .sio-statistics-list-item {
      @apply odd:pl-0 even:pr-0 even:pl-3;
    }
    li {
      @apply m-0 text-muted-dark first:pl-0 last:pr-0;
    }
    > div {
      @apply m-0 p-3;
    }
    p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .sio-editor-content .sio-status {
    @apply relative pl-5;
    @apply before:size-3 before:absolute before:right-0 before:top-0 before:content-[''] before:rounded-full before:left-0 before:top-1;
    &.sio-status-green {
      @apply before:bg-[#4DC39B];
    }
    &.sio-status-perpal {
      @apply before:bg-[#8670D3];
    }
    &.sio-status-blue {
      @apply before:bg-[#47A4EA];
    }
    &.sio-status-orange {
      @apply before:bg-[#E6B350];
    }
    &.sio-status-red {
      @apply before:bg-[#CF5768];
    }
    &.sio-status-065E62 {
      @apply before:bg-[#065E62];
    }
    &.sio-status-436593 {
      @apply before:bg-[#436593];
    }
    &.sio-status-F77D7A {
      @apply before:bg-[#F77D7A];
    }
    &.sio-status-F6CDAD {
      @apply before:bg-[#F6CDAD];
    }
    &.sio-status-8CD294 {
      @apply before:bg-[#8CD294];
    }
    &.sio-status-83A6DE {
      @apply before:bg-[#83A6DE];
    }
    &.sio-status-B5496B {
      @apply before:bg-[#B5496B];
    }
    &.sio-status-F7BFDC {
      @apply before:bg-[#F7BFDC];
    }
    &.sio-status-ADE8F0 {
      @apply before:bg-[#ADE8F0];
    }
    &.sio-status-5365AD {
      @apply before:bg-[#5365AD];
    }
    &.sio-status-3A916E {
      @apply before:bg-[#3A916E];
    }
    &.sio-status-FFBA93 {
      @apply before:bg-[#FFBA93];
    }
  }
  .sio-recomandation-content{
    @apply ml-4 overflow-hidden;
  }
  .sio-recomandation-content > div > img{
    display: none;
  }


  /* Styling the text area */
  textarea {
    overflow-y: scroll; /* Enable vertical scrolling */
    scrollbar-width: 4px; /* Firefox */
    scrollbar-color: #e4e4e4 transparent; /* Firefox */
  }

  /* Custom scrollbar styles for Webkit browsers */
  textarea::-webkit-scrollbar {
    width: 4px; /* Width of the scrollbar */
  }

  textarea::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 4px;
  }

  textarea::-webkit-scrollbar-thumb:hover {
    background-color: transparent;
  }

  textarea::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 4px;
  }

  .sio-editor-content .sio-card-shadow.sio-table-card-sm{
    @apply max-md:bg-transparent max-md:shadow-none max-md:border-none;
  }
  .sio-editor-content .sio-table-card-sm .sio-table-border{
    @apply max-md:border-none;
  }
  .sio-editor-content .sio-table-card-sm .sio-card-header{
    @apply max-md:bg-white max-md:shadow-md max-md:rounded-lg max-md:border max-md:p-3 max-md:mb-3 max-md:z-10;
  }
  .sio-editor-content .sio-table-card-sm thead{
    @apply max-md:hidden;
  }
  .sio-editor-content .sio-table-card-sm tbody{
    @apply max-md:flex max-md:gap-4 max-md:border-none;
  }
  .sio-editor-content .sio-table-card-sm tbody tr{
    @apply max-md:w-full max-md:flex max-md:flex-col max-md:pb-4 max-md:flex-wrap max-md:shadow-md max-md:rounded-lg max-md:border;
  }
  .sio-editor-content .sio-table-card-sm tbody td{
    @apply max-md:border-0 max-md:!pb-0 max-md:p-4 max-md:w-[220px] max-md:leading-snug;
  }
  .sio-editor-content .sio-table-card-sm tbody td[data-title]:before {
      color: rgba(0, 0, 0, .54);
      @apply max-md:text-muted34-dark max-md:font-semibold max-md:mb-1 max-md:text-[10px] max-md:content-[attr(data-title)] leading-snug uppercase block;
  }
  

}
