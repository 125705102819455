/* custom-base.css */
@tailwind base;
/* Custom base styles */
@layer base {
  /* Set a default font family for the entire document */
  body {
    @apply font-manrope;
    line-height: 1.6;
    color: #333; /* Default text color */
    @apply bg-white;
  }

  /* Define heading styles */
  h1, h2, h3, h4, h5, h6 {
    @apply font-montserrat leading-tight;
  }

  h1 {
    @apply text-2xl text-white font-bold;
  }

  h2 {
    @apply text-xl text-primary-dark font-medium;
  }

  h6 {
    @apply text-sm text-white font-bold;
  }

  /* Style for links */
  a {
    text-decoration: none; /* Remove underline */
  }

  a:hover {
    text-decoration: underline; /* Add underline on hover */
  }
}