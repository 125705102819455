@tailwind components;
@layer components {
  .sio-accordion{
    .sio-accordion-header{

    }
    .sio-accordion-body{
      @apply my-3 overflow-y-scroll scrollbar-none scrollbar-thumb-gray-500 scrollbar-track-gray-700 max-h-full;
    }
    .sio-accordion-body.sio-y-scroll{
      @apply my-3 overflow-y-scroll scrollbar-none scrollbar-thumb-gray-500 scrollbar-track-gray-700 max-h-48;
    }
  }
  .sio-accordion-card .Mui-focusVisible {
    box-shadow: none !important;
    background-color:transparent !important;
  }

  .sio-accordion-table{
    .sio-accordion-item{
      @apply max-lg:border-none max-lg:bg-transparent max-lg:shadow-none;
      .MuiAccordionSummary-root{
        @apply max-lg:border-solid max-lg:border max-lg:border-dark/15 max-lg:rounded-lg max-lg:mb-4
      }
    }
  }

}