@tailwind components;
@layer components {
  /* Base button styles */
  .sio-btn {
    @apply px-4 py-3 font-bold text-sm text-white font-montserrat rounded-lg transition-colors duration-300 ease-in-out focus:outline-none shadow;
  }
  .btn-sm{
    @apply py-2 px-4 leading-tight;
  }
  .btn-xs{
    @apply py-1 px-2 leading-tight text-xs;
    svg{
      height: 12px;
      width: 12px;
    }
  }
  /* Primary button */
  .btn-primary {
    @apply bg-primary hover:bg-primary-900 focus:bg-primary-900;
    svg{
      @apply fill-white;
    }
  }
  /* Secondary button */
  .btn-secondary {
    @apply bg-secondary hover:bg-secondary-900 focus:bg-secondary-900;
  }
  .btn-dark {
    @apply bg-dark-700 hover:bg-dark-600 focus:bg-dark-600;
    svg{
      @apply fill-dark;
    }
  }
  .btn-default{
    @apply text-primary-dark bg-dark/10 shadow-none hover:bg-dark/20;
  }
  /* Outlined button */
  .btn-outlined {
    @apply border border-blue-500 text-blue-500 hover:bg-blue-50 focus:ring-blue-400;
  }
  .sio-btn-icon{
    @apply inline-flex items-center gap-3 text-xs sm:text-sm px-3 sm:px-4;
  }
  .sio-link-primary{
    @apply text-link-primary font-semibold hover:no-underline;
    text-decoration: underline;
  }
  .sio-link-secondary{
    @apply text-link-secondary font-semibold hover:no-underline;
    text-decoration: underline;
  }
  .sio-link{
    @apply inline-flex items-center uppercase font-bold hover:no-underline underline;
  }
  .sio-btn-link{
    @apply !bg-transparent shadow-none outline border-none outline-none underline font-semibold hover:no-underline;
  }
  .sio-link-icon{
    @apply inline-flex items-center gap-1 uppercase font-bold;
  }
  .soi-btn-fab{
    @apply w-8 h-8 rounded-full flex items-center justify-center p-0;
    &.btn-lg{
      @apply w-10 h-10;
    }
  }
  .soi-btn-flat{
    @apply shadow-none bg-transparent;
    &.btn-default{
      @apply text-muted-dark hover:bg-dark/10 hover:text-primary-dark;
    }
    &.btn-dark{
      @apply text-primary-dark hover:bg-dark/10 hover:text-primary-dark focus:bg-dark/10 active:bg-dark/10;
    }
    &.btn-light{
      @apply text-muted-light hover:bg-dark/30 hover:text-primary-light focus:bg-dark/30 active:bg-dark/30;
    }
  }
  .btn-outline-dark{
    @apply border-dark/10 border shadow-none hover:bg-dark text-dark hover:text-white;
    svg{
      @apply fill-dark/75;
    }
    &:hover svg {
      @apply !fill-white;
    }
    &:hover svg path{
      @apply !fill-white;
    }
  }

  .btn-outline-light{
    @apply border-light border shadow-none hover:bg-white;
    svg{
      @apply fill-white/75;
    }
    &:hover svg {
      @apply fill-dark;
    }
  }
  .sio-btn-file{
    @apply flex items-center px-2 py-2 text-sm transition bg-secondary-200 rounded-lg text-secondary-dark cursor-pointer hover:bg-secondary-800 hover:text-white;
    svg{
      @apply fill-darkGray;
    }
    &:hover{
      svg{
        @apply fill-white;
      }
    }
  }

  .roi-zendesk-btn{
    @apply bg-[#545454] size-10 m-auto mb-3 hover:bg-[#111111];
  }
  .roi-zendesk-btn.roi-zendesk-btn-active{
    @apply bg-secondary/30;
    path{
      @apply fill-secondary-700;
    }
  }
  
}

.sio-btn-role{
  svg{
    fill: rgba(255, 255, 255, 0.34);
    @apply size-5 sm:size-6
  }
}
.sio-btn-role.sio-btn-active{
  background-color:rgba(255, 255, 255, 0.54);
  @apply text-primary-dark;
  svg{
    fill: rgba(17, 17, 17, 0.84);
  }
}

.sio-google-btn{
  @apply font-bold text-sm text-white bg-white overflow-hidden font-montserrat rounded-lg transition-colors duration-300 ease-in-out focus:outline-none;
  height: 40px;
}
.S9gUrf-YoZ4jf iframe{
    width: 372px !important;
    max-width: 372px !important;
    @media screen and (max-width: 640px) {
      width: 314px !important;
      max-width: 314px !important;
    }
}

.sio-bookmarked{
  @apply group-hover/item:visible group-hover/item:bg-gradient-to-l
}

.sio-btn-hover-primary{
  @apply hover:bg-primary/15 hover:text-primary-800;
  svg{
    @apply !fill-dark/75;
  }
  &.sio-btn-active{
    @apply bg-primary/15 text-primary-800;
  }
}
.sio-btn-hover-primary:hover svg{
  @apply !fill-dark/75;
}
.sio-btn-hover-primary:hover svg path{
  @apply !fill-dark/75;
}
.sio-btn:disabled{
  @apply opacity-65 hover:bg-secondary;
}