div {
  box-sizing: border-box;
  margin: 0;
}

body {
  margin: 0;
  overflow-x: hidden;
}

.custom-scroll::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

.custom-scroll::-webkit-scrollbar-corner {
  background-color: transparent;
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: rgba(125, 125, 125, 0.15);
  border-radius: 20px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #cbcdcc;
  border-radius: 20px;
}

.custom-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.list-disc-custom {
  list-style-type: disc;
  color: white;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.custom-toast {
  background-color: #ccf5e9 !important;
  color: #333 !important;
  box-shadow: none !important;
}

.handsontable .custom-header {
  background-color: #027b57; /* Dark blue */
  color: #ffffff; /* White text */
  font-weight: bold;
  text-align: center;
}

.handsontable .header-highlight {
  background-color: #1e293b; /* Dark blue for header */
  color: #ffffff; /* White text */
  font-weight: bold;
}

.chart-container,
.table-container {
  page-break-inside: avoid;
}
