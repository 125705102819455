.list__wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-x: auto;
  background-color: white;
  height: 100vh;
  border-radius: 10px;
  padding-left: 20px;
  padding-top: 20px;

  & > div {
    &.header__ele {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: sticky;
      left: 0;
      gap: 10px;

      & > span {
        color: var(--_primary-500);
        font-weight: 500;
      }

      & > button {
        padding: 10px;
        min-width: 70px;
        border: 1px solid var(--_primary-500);
        color: var(--_primary-500);
        font-size: 14px;
        text-transform: uppercase;
        background: transparent;
        cursor: pointer;
      }
    }
  }

  & > table {
    background: #f9f9f9;
    padding: 10px;
    border-radius: 4px;
    border-collapse: collapse;
    min-width: 500px;
    max-width: 100%;
    & th,
    td {
      border: 1px solid #c2c2c2;
      padding: 10px;
      text-align: center;
    }
    & tr {
      &[data-editing="true"] {
        & td {
          border: 2px solid var(--_primary-500);
          background: #0064d96b;
        }
      }
    }

    & td {
      font-size: 14px;
      background: #fff;

      &[data-type="filetype"] {
        & > div {
          display: flex;
          align-items: center;
          justify-content: center;

          & input {
            border: none;
            &:focus {
              outline: none;
            }
            text-align: center;
          }
        }
      }

      &[data-type="actions"] {
        min-width: 150px;

        display: flex;
        flex-direction: row;
        justify-content: center;

        & button {
          padding: 8px;
          min-width: 40px;
          border: 1px solid var(--_primary-500);
          color: var(--_primary-500);
          font-size: 13px;
          text-transform: uppercase;
          background: transparent;
          cursor: pointer;

          &:hover {
            background-color: var(--_primary-500);
            color: #fff;
          }
        }

        & div {
          display: flex;
          gap: 4px;
          justify-content: center;

          & > button {
            cursor: pointer;
            padding: 4px;
            background: transparent;
            border: none;
            display: var(---);

            &[data-type="Delete"] {
              --_svg-color: red;
            }

            &:hover {
              --_hover-value: -5px;
            }

            svg {
              pointer-events: none;
              width: 20px;
              height: 20px;
              transform: translateY(var(--_hover-value, 0px));
              transition: transform 0.45s ease-in;
              & path {
                stroke: var(--_svg-color, #020202);
              }
            }
          }
        }
      }
    }
  }
}
