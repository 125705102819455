.form__ele {
  position: fixed;
  inset: 0 0 0 0;
  background: rgba(0, 0, 0, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    &.wrapper {
      background: #fff;
      padding: 0px 16px 10px;
      border-radius: 6px;
      width: 500px;
      max-width: 100%;
      max-height: calc(100vh - 100px);
      overflow-y: auto;

      display: flex;
      flex-direction: column;
      row-gap: 8px;

      & > p {
        font-size: 20px;
        font-weight: 600;
        color: var(--_primary-500);
        position: sticky;
        padding-block: 10px;
        top: 0px;
        background: #fff;
        border-block-end: 1px solid var(--_primary-500);
        padding-block-end: 8px;
      }
      & > form {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        background: #f1f1f1;
        padding: 16px 16px;
        border-radius: 6px;
        --_input-height: 40px;

        & > .form__group {
          display: flex;
          flex-direction: column;
          gap: 6px;

          &[data-type="dropdown"] {
            & > div {
              position: relative;
              width: 100%;
              border: none;

              &:has(div[data-state="active"]) {
                --_self-active-transform: 0px;
                --_self-active-opacity: 1;
                --_self-active-visibility: visible;
              }

              & > div {
                display: flex;
                gap: 4px;
                background: #fff;
                border: 1px solid #c2c2c2;
                align-items: center;
                justify-content: space-between;
                height: var(--_input-height);
                padding-inline: 10px;
                user-select: none;
                cursor: pointer;
                & * {
                  pointer-events: none;
                }

                &[data-state="active"] {
                  border-color: var(--_primary-500);
                  --_self-active-color: #020202;
                  --_icon-rotate: 180deg;
                }

                & > span {
                  display: flex;

                  svg {
                    width: 18px;
                    height: 18px;
                    rotate: var(--_icon-rotate, 0deg);
                    transition: rotate 0.45s ease-in;
                  }
                }
                & > p {
                  display: flex;
                  align-items: center;
                  color: var(--_self-active-color, #c1c1c1);
                  font-size: 14px;

                  &[data-has-data="false"] {
                    --_self-active-color: #020202;
                  }
                }
              }

              & > ul {
                position: absolute;
                background: #fff;
                padding: 10px;
                width: 100%;
                top: calc(var(--_input-height) + 8px);
                display: flex;
                flex-direction: column;
                text-align: start;
                max-height: 200px;
                overflow-y: auto;
                transform: translateY(var(--_self-active-transform, -10px));
                opacity: var(--_self-active-opacity, 0);
                visibility: var(--_self-active-visibility, hidden);
                transition: all 0.45s ease-in-out;
                gap: 4px;
                z-index: 99;

                & > li {
                  display: flex;
                  padding: 10px 10px 10px 10px;
                  gap: 8px;
                  align-items: center;
                  cursor: pointer;
                  user-select: none;
                  * {
                    pointer-events: none;
                  }

                  &:hover,
                  &:has(input:checked) {
                    background: #f1f1f1;
                  }

                  & > input {
                    width: 18px;
                    height: 18px;
                    accent-color: var(--_primary-500);
                    /* pointer-events: all; */
                    border: 1px solid var(--_primary-500);
                  }
                  & > p {
                    font-size: 14px;
                  }
                }
              }
            }
          }

          & label {
            font-size: 14px;
          }

          & input {
            border: 1px solid #c2c2c2;
            background: #fff;
            font-size: 14px;
            color: #020202;
            &::placeholder {
              color: #c1c1c1;
            }
            &[type="text"] {
              height: var(--_input-height);
              padding-inline: 10px;
            }

            &:focus {
              outline: none;
              border-color: var(--_primary-500);
            }
          }

          &[data-element-type="recommendations"] {
            & > div {
              & input {
                width: 100%;
                border: none;
                background: transparent;
                padding: 0px;
                pointer-events: all;
              }
              & > ul {
                top: auto;
                bottom: calc(var(--_input-height) + 8px);
                transform: scale(1);
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }

        & > .btn__group {
          display: flex;
          gap: 8px;
          align-items: center;

          & > button {
            padding: 10px;
            font-size: 14px;
            text-transform: capitalize;
            font-weight: 500;
            border-radius: 4px;
            border: none;
            min-width: 70px;
            cursor: pointer;

            &[data-type="Save"],
            &[data-type="Update"] {
              background: var(--_primary-500);
              /* color: #fff; */
            }
            &[data-type="Cancel"] {
              border: 1px solid var(--_primary-500);
              color: var(--_primary-500);
            }
          }
        }
      }
    }
  }
}
