@tailwind components;
@layer components {
  .sio-file-types-table {
    .sio-card-header {
      @apply sticky top-0 bg-white rounded-md;
    }
    .sio-table .sio-table-thead {
      @apply sticky top-[70px];
    }
  }
  .sio-short {
    @apply flex items-center gap-2 hover:cursor-default;
  }
  .sio-short .sio-short-icon {
    @apply opacity-55;
  }
}
