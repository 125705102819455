@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;1,200;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

@import "./assets/styles/base/base.scss";
@tailwind components;
@tailwind utilities;

@import "./assets/styles/components/components.scss";
@import "./assets/styles/pages/pages.scss";
/* @import "./assets/styles/global.css"; */
