@tailwind components;
@layer components {
  .sio-circle-icon {
    @apply flex items-center justify-center w-8 h-8 rounded-full text-white;
  }
  .sio-circle-primary {
    @apply bg-primary/20;
    svg {
      @apply fill-primary-600 w-4 h-4;
    }
  }
  .sio-circle-secondary {
    @apply bg-secondary/20;
    svg {
      @apply fill-secondary-600 w-[15px] h-[15px];
    }
  }
  .sio-circle-warning {
    @apply bg-warning/20;
    svg {
      @apply fill-warning w-[15px] h-[15px];
    }
  }
  .sio-circle-light {
    @apply bg-white/10;
    svg {
      @apply fill-white/50 w-4 h-4;
    }
  }
  .sio-avatar {
    @apply flex items-center justify-center w-10 h-10 rounded-full text-white overflow-hidden;
    &.sio-avatar-dark{
      @apply bg-white/20 text-base text-muted-light;
    }
    &.sio-avatar-active{
      @apply outline-primary-800 outline-2 outline;
    }
  }
}
