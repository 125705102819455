@tailwind components;
@layer components {
  .sio-loader-container{
    @apply border-b self-center;
    display: flex;
    padding-top: 15px;
  }
  .circle:nth-child(1),
  .circle:nth-child(2),
  .circle:nth-child(3),
  .circle:nth-child(4),
  .circle:nth-child(5) {
    width: 8px;
    height: 8px;
    border-radius: 2em;
    display: inline-flex;
    animation: Bounce 0.9s ease-in infinite;
    margin: 0 2px;
  }
  .circle:nth-child(1) {
    @apply bg-primary;
  }
  .circle:nth-child(2) {
    @apply bg-secondary;
    animation-delay: 0.15s;
  }
  .circle:nth-child(3) {
    @apply bg-warning;
    animation-delay: 0.25s;
  }
  .circle:nth-child(4) {
    @apply bg-dark;
    animation-delay: 0.45s;
  }

  @keyframes Bounce {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-15px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  .file-upload-container.drag-over {
    border: 2px dashed #ccc;
    background-color: #f0f0f0;
  }
  .file-upload-container {
    border: 2px solid #ccc;
    padding: 20px;
    text-align: center;
    transition: background-color 0.2s ease;
  }
  .drag-info {
    margin-top: 10px;
    font-size: 14px;
    color: #888;
  }
  .error-message {
    margin-top: 10px;
    color: red;
  }


  .sio-loader {
    position: relative;
    margin: 0 auto;
    width: 54px;
    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }

  .sio-loader-circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .sio-loader-logo{
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }

  @keyframes color {
    100%,
    0% {
      stroke: #ff0099;
    }
    40% {
      stroke: #00cc8f;
    }
    66% {
      stroke: #F5B937;
    }
    80%,
    90% {
      stroke: #00cc8f;
    }
  }

  .showbox {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5%;
  }


}
