@tailwind components;
@layer components {
  .sio-list-disc {
    @apply flex flex-col text-[13px] gap-3;
    &.sio-list-dark {
      li {
        @apply leading-tight text-secondary-light hover:text-secondary-700 relative;
        &::before {
          @apply content-[''] bg-white/10 size-2 absolute left-0 rounded-full top-1;
        }
      }
      li:hover::before {
        @apply text-white/50;
      }
    }
    &.sio-list-secondary {
      li {
        @apply text-secondary-dark;
      }
      li::marker {
        @apply text-secondary-700 text-lg;
      }
    }
    &.sio-nav-list {
      li {
        @apply pl-4;
      }
    }
  }
  .sio-checkbox-list {
    margin-left: -5px;
    margin-right: -5px;
  }
  .sio-checkbox-list-item::before {
    @apply border-b absolute bottom-0 left-3 right-3;
    content: "";
  }
  .sio-checkbox-list-item {
    @apply items-center justify-center -mx-1 border border-transparent py-4 px-3 relative -mt-[2px];
    .sio-circle-icon {
      @apply grayscale;
    }
    &.sio-checkbox-list-item-active {
      @apply border border-primary/20 shadow rounded-lg !important;
    }
    &.sio-checkbox-list-item-active .sio-circle-icon {
      @apply grayscale-0;
    }
    &.sio-checkbox-list-item-active::before {
      border-bottom-color: transparent;
    }
  }
  .sio-checkbox-list-item:last-child::before {
    border-bottom-color: transparent;
  }

  .sio-list-divide {
    @apply divide-x divide-dark leading-none items-center flex;
  }
  .sio-list-divide .sio-list-item {
    @apply px-3;
  }
  .sio-list-divide .sio-list-item:first-child {
    @apply ps-0;
  }
  .sio-list-divide .sio-list-item:last-child {
    @apply pe-0;
  }
  .sio-list-decimal > li {
    counter-increment: custom-counter; /* Increment counter for each li */
    list-style: none; /* Remove default list style if needed */
    @apply ps-8 relative;
  }
  .sio-list-decimal > li::before {
    content: counter(custom-counter) "";
    @apply bg-warning text-primary-dark rounded-full size-5 absolute flex justify-center items-center left-0 text-[10px] font-bold;
  }

  .sio-list-divide-y {
    @apply divide-y;
  }
  .sio-list-divide-y .sio-list-item {
    @apply py-3 px-4;
  }
  .sio-list-bg li {
    @apply !px-2 !py-[6px] before:!top-[10px] before:!-left-3;
  }
  .sio-list-bg li:hover {
    @apply bg-white/10 !text-primary-light rounded-lg;
  }
  .sio-list-bg li:hover .sio-bookmark-icon {
    @apply visible bg-gradient-to-l from-[#303030];
  }
  .sio-list-bg .sio-bookmark-icon {
    @apply from-50% z-10 rounded-lg absolute flex items-center right-0 top-0 pl-4 bottom-0 m-auto invisible;
  }
}
