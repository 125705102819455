@tailwind components;
@layer components {
  .sio-col-420 {
    @apply max-w-[420px] flex flex-col items-center mx-auto w-full;
  }
  .sio-col-460 {
    @apply max-w-[460px] flex flex-col items-center mx-auto w-full;
  }
  .sio-card {
    @apply flex flex-col sm:p-8 xs:p-5 w-full rounded-b-2xl gap-6;
  }
  .sio-card-sm {
    @apply flex flex-col p-4 w-full rounded-lg gap-4 border;
    .sio-card-header{
      @apply p-4;
    }
  }
  .sio-divider {
    display: flex;
    align-items: center;
    text-align: center;
    @apply text-muted34-light font-montserrat font-normal text-xs;
    &::before,
    &::after {
      content: '';
      display: block;
      height: 1px;
      @apply bg-divide-light mx-2;
      flex-grow: 1;
    }
  }
  .sio-card-shadow{
    @apply shadow-md w-full rounded-lg border;
  }
  .sio-card-lg{
    @apply p-6;
  }

  .sio-card-icon-dark{
    @apply bg-white/[0.03] rounded-lg border-white/5 hover:border-white/30 border py-2 md:py-4 px-2 text-center flex md:flex-col items-center cursor-pointer;
    .sio-avatar{
      @apply bg-white/10 md:size-12 me-2 md:me-0 md:mb-2;
      svg{
        @apply size-5 md:size-6;
      }
    }
    &:hover{
      p{
        @apply text-primary-light;
      }
      .sio-avatar{
        @apply bg-white/10;
        svg{
          @apply opacity-100;
        }
      }
    }
  }
  .sio-card-icon-dark.active{
    p{
      @apply text-primary-light;
    }
    .sio-avatar{
      @apply bg-secondary/30;
      svg{
        @apply opacity-100;
      }
    }
  }

  #launcher {
    left: 20px !important;
    right: auto !important;
    display: none;
  }

  #webWidget{
    left: 65px !important;
    right: auto !important;
    bottom: 0 !important;
  }
  /* Move Zendesk widget to the bottom-left */
  iframe[title="Messaging window"] {
    left: 80px !important;
    right: auto !important;
    bottom: 20px !important;
  }
  /* Move Zendesk widget to the bottom-left */
  iframe[title="Close message"] { 
    display: none !important;
  }
  iframe[title="Message from company"] {
    display: none !important;
  }

}