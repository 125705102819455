@tailwind components;
@layer components {
  .sio-badge-dark-link{
    @apply bg-dark-800 hover:text-white hover:no-underline border border-white/0 hover:border-white text-xs text-muted-light rounded-lg font-medium py-2 px-4;
  }
  .sio-badge-dark-link.active{
    @apply bg-white/55 text-dark no-underline hover:bg-white;
  }
  .sio-badge{
    @apply text-xs font-semibold rounded-md leading-none px-1 py-1 shadow-sm inline-flex;
    &.sio-badge-light{
      @apply bg-dark/10 text-secondary-dark;
    }
    &.sio-badge-warning{
      @apply bg-warning text-secondary-dark;
    }
    &.sio-badge-secondary{
      @apply bg-secondary text-secondary-dark;
    }
  }
  .sio-badge-dot{
    @apply size-1;
  }
}