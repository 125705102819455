@tailwind components;
@layer components {
  .sio-sidebar.sio-sidebar-open .sio-sidebar-body {
    @apply pb-20 md:pb-6 p-6 pt-5;
  }
  .sio-sidebar.sio-sidebar-open .sio-sidebar-brand img {
    @apply h-6;
  }
  .sio-sidebar {
    padding: 0 !important;
    @apply text-primary-light h-0 md:h-full fixed bottom-0 md:top-0 left-0 md:left-20 duration-300 md:block w-full md:w-0 bg-dark;
    &.sio-sidebar-open {
      @apply w-full md:w-[19rem] h-full p-0 left-0 md:left-20 z-[999];
    }
    &.sio-sidebar-open::after {
      @apply right-0 left-0 top-0 bottom-0 z-0 fixed bg-white/50 !block md:!hidden;
      content: "";
    }
    .sio-sidebar-container {
      @apply bg-dark z-[1000] relative;
    }
  }
  .sio-sidebar-brand {
    @apply bg-white-gradient justify-between px-6 items-center flex rounded-b-2xl min-h-[80px];
  }
  .sio-sidebar-body {
    @apply w-full h-screen overflow-hidden pb-20 md:pb-5 md:p-5 justify-center;
  }
  .sio-user-info {
    @apply flex items-center justify-between bg-white/10 rounded-full p-2 m-3;
  }

  .sio-sidebar-toggle {
    @apply absolute left-0 md:left-auto md:-right-3 -bottom-3 -rotate-90 md:rotate-0;
  }

  .sio-vertical-navbar {
    @apply bg-[#292929] md:bg-white/10 w-full bottom-0 md:w-20 flex flex-col z-[9999] fixed md:relative;
  }
  .sio-sidebar-contener {
    @apply flex md:ml-0 z-[60] md:relative transition-all;
    &.sio-sidebar-open {
      @apply ml-0;
    }
  }

  .sio-brand-icon-logo {
    @apply py-6 px-4 hidden md:flex;
  }
  .sio-brand-icon-logo-mobile {
    @apply py-6 px-4;
  }
  .sio-vertical-nav {
    @apply mb-auto flex md:flex-col justify-around md:justify-center;
  }
  .sio-vertical-nav .sio-nav-item {
    @apply mx-1 my-2 md:my-4;
  }
  .sio-nav-icon .sio-nav-link {
    @apply flex flex-col justify-center items-center text-[9px] text-muted-light no-underline;
  }

  .sio-nav-link-icon {
    @apply flex items-center justify-center rounded-full px-4 py-2 mb-1;
    svg {
      @apply fill-muted-light size-6;
    }
  }
  .sio-nav-icon .sio-nav-link:hover {
    @apply text-white;
    .sio-nav-link-icon {
      @apply bg-dark;
      svg {
        @apply fill-muted-light;
      }
    }
  }
  .sio-nav-icon .sio-nav-link.active .sio-nav-link-icon {
    @apply bg-secondary/30;
    svg {
      @apply fill-secondary-700;
    }
  }
}

.Mui-checked ~ span {
  @apply text-primary-light;
}

.sio-bookmarked {
  @apply !visible !bg-gradient-to-l !from-[#111];
}

.sio-sidebar-header{
  @apply px-4 py-3 md:p-6 shadow-md;
}
.sio-sidebar-header .sio-sidebar-close{
  @apply size-7 md:size-10;
  svg {
    @apply size-5 md:size-6
  }
}
.sio-sidebar-footer {
  @apply p-4 md:p-6;
}
.sio-selected {
  @apply !bg-secondary/10;
}
.sio-org-menu {
  .MuiMenu-paper {
    border-radius: 10px !important;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1) !important;
    min-width: 250px !important;
    @apply scrollbar-none;
  }
}

.sio-menu-header {
  @apply flex px-3 py-2 bg-[#F7F5F2] -mt-2 sticky text-xs text-muted-dark top-0 z-10 items-center;
}

.sio-org-menu-item {
  &.sio-org-selected {
    @apply bg-secondary/10 !text-primary-dark;
  }
}

.sio-org-radio {
  width: 18px !important;
  height: 18px !important;
  border-radius: 20px !important;
  opacity: 0px !important;

  border: 2px solid #111111 !important;
  margin-right: 8px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  transition: background-color 0.3s ease !important;

  &.sio-org-radio-selected {
    background-color: #00cc8f !important;
  }

  .sio-org-radio-inner {
    width: 8px !important;
    height: 8px !important;
    gap: 0px !important;
    border-radius: 20px !important;
    opacity: 0px !important;

    background-color: white !important;
  }
}

.sio-org-btn {
  width: 100% !important;
}

.sio-org-btn-secondary {
  background-color: rgb(0 163 114 / 1) !important;
}
